import { useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ComingSoon from "./pages/ComingSoon";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import "./sass/App.scss";

function App() {
 const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
   document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
 };
 return (
  <>
   <Header />
   <main>
    <Wrapper>
     <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/coming-soon" element={<ComingSoon />} />
     </Routes>
    </Wrapper>
   </main>
   <Footer />
  </>
 );
}

export default App;
