import React from "react";
import { NavLink } from "react-router-dom";
import FacebookIcon from "../assets/facebook-icon.svg";
import InstagramIcon from "../assets/instagram-icon.svg";
import LinkedinIcon from "../assets/linkedin-icon.svg";
import Logo from "../assets/Logo.svg";
import "../sass/Footer.scss";

const Footer = () => {
 return (
  <footer>
   <div className="container">
    <div className="footer__top">
     <h1 className="logo">
      <a href="/">
       <img src={Logo} alt="" />
      </a>
     </h1>
     <div className="footer__links">
      <NavLink to="/coming-soon">Case Studies</NavLink>
      <NavLink to="/coming-soon">Media</NavLink>
      <NavLink to="/contact">Contact Us</NavLink>
     </div>
     <div className="social__links">
      <a
       href="https://www.instagram.com/air.zones/"
       target="_blank"
       rel="noreferrer">
       <img src={InstagramIcon} alt="" />
      </a>
      <a
       href="https://www.facebook.com/airzones.uae"
       target="_blank"
       rel="noreferrer">
       <img src={FacebookIcon} alt="" />
      </a>
      <a
       href="https://www.linkedin.com/company/airzones/"
       target="_blank"
       rel="noreferrer">
       <img src={LinkedinIcon} alt="" />
      </a>
     </div>
    </div>
    <div className="footer__bottom">
     <p>© 2024 AirZone. All rights reserved.</p>
    </div>
   </div>
  </footer>
 );
};

export default Footer;
