import React from "react";
import FacebookIcon from "../assets/facebook-icon.svg";
import InstagramIcon from "../assets/instagram-icon.svg";
import LinkedinIcon from "../assets/linkedin-icon.svg";
import "../sass/ComingSoon.scss";

const ComingSoon = () => {
 return (
  <div className="comingSoon__page">
   <h2>Coming Soon</h2>
   <div className="social__links">
    <a
     href="https://www.instagram.com/air.zones/"
     target="_blank"
     rel="noreferrer">
     <img src={InstagramIcon} alt="" />
    </a>
    <a
     href="https://www.facebook.com/airzones.uae"
     target="_blank"
     rel="noreferrer">
     <img src={FacebookIcon} alt="" />
    </a>
    <a
     href="https://www.linkedin.com/company/airzones/"
     target="_blank"
     rel="noreferrer">
     <img src={LinkedinIcon} alt="" />
    </a>
   </div>
  </div>
 );
};

export default ComingSoon;
