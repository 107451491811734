import React from "react";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import "../sass/ListingSection.scss";

const ListingSection = ({
 className,
 flexReverse,
 Icon,
 title,
 textList,
 Img,
 contactBtn,
 ...props
}) => {
 return (
  <div className={`listing ${className}`} {...props}>
   <div
    className={`container ${flexReverse ? "pl-0" : "pr-0"}`}
    style={{
     flexDirection: flexReverse && "row-reverse",
    }}>
    <div className="listing__left">
     <img src={Icon} alt="" className="icon" />
     <h3>{title}</h3>
     <ul>
      {textList?.map((text) => (
       <li>{text}</li>
      ))}
     </ul>
     {contactBtn && (
      <button className="button button--primary button--hover">
       <Link to="/contact">Contact Us</Link>
      </button>
     )}
    </div>
    <Fade
     triggerOnce
     fraction={0.5}
     className={`listing__right ${flexReverse && "flex__reverse"}`}>
     <img src={Img} alt="" />
    </Fade>
   </div>
  </div>
 );
};

export default ListingSection;
