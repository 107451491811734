import React, { useState, useEffect } from "react";
import { Toast, notifyOnline, notifyOffline } from "../Toast"; // Adjust the path to your Toast.js
import FacebookIcon from "../assets/facebook-icon.svg";
import InstagramIcon from "../assets/instagram-icon.svg";
import LinkedinIcon from "../assets/linkedin-icon.svg";
import "../sass/Contact.scss";

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        try {
            const response = await fetch('https://airzones-board.com/AirzonesAPI/Airzonescontactform', {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                mode: 'cors',
            });
    
            const data = await response.json();
            if (response.ok) {
                alert(data.message);
                setFormData({ name: '', email: '', phone: '', message: '' });
            } else {
                alert(`Failed to send message: ${data.message}`);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again later.');
        }
    };

    // Offline/Online Notification Logic
    useEffect(() => {
        const handleOnline = () => {
            notifyOnline();
        };

        const handleOffline = () => {
            notifyOffline();
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return (
        <div className="contact__page">
            <Toast /> {/* Make sure the Toast component is rendered here */}
            <section className="section__one">
                <div className="container">
                    <h2 className="section__title">
                        Get in Touch. <span>We're Here</span> to Assist You.
                    </h2>
                    <p className="section__text center">
                        Request a free consultation and discover how AirZones can help you create
                        a healthier indoor environment.
                    </p>
                    <div className="section__body">
                        <div className="sectionBody__left">
                            <form onSubmit={handleSubmit}>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Your Name"
                                    required
                                    className="input__field"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email Address"
                                    required
                                    className="input__field"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <input
                                    type="tel"
                                    name="phone"
                                    placeholder="Phone Number (optional)"
                                    className="input__field"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                                <textarea
                                    name="message"
                                    placeholder="Message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className="input__field input__field--textarea"
                                />
                                <button type="submit" className="button button--primary">
                                    Send Message
                                </button>
                            </form>
                        </div>
                        <div className="sectionBody__right">
                        <div className="section__part">
        <h3>AirZones Europe</h3>
        <p>124 City Road, London EC1V 2NX,United Kingdom</p>
       </div>
       <div className="section__part">
        <h3>AirZones Middle East</h3>
        <p>
         #307,Building 6 Bay Square, Business Bay PO Box:75633, Dubai United
         Arab Emirates
        </p>
       </div>
       <div className="section__part">
        <p>
         Email: <a href="mailto:info@airzones.com">info@airzones.com</a>
        </p>
       </div>
       <div className="social__links">
        <a
         href="https://www.instagram.com/air.zones/"
         target="_blank"
         rel="noreferrer">
         <img src={InstagramIcon} alt="" />
        </a>
        <a
         href="https://www.facebook.com/airzones.uae"
         target="_blank"
         rel="noreferrer">
         <img src={FacebookIcon} alt="" />
        </a>
        <a
         href="https://www.linkedin.com/company/airzones/"
         target="_blank"
         rel="noreferrer">
         <img src={LinkedinIcon} alt="" />
        </a>
       </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Contact;
