import React from "react";
import StarIcon from "../assets/star.svg";
import StarFillIcon from "../assets/start-fill.svg";
import StarHalfFillIcon from "../assets/start-halfFill.svg";
import "../sass/AirzonesCard.scss";

const AirZonesCard = ({ currentReview, previousReview, bgImg }) => {
 return (
  <div className="card">
   <img src={bgImg} alt="" className="bg__img" />
   <div className="card__details">
    <p className="card__title">AirZones Rating </p>
    <div className="cardDetails__bottom">
     <div className="cardDetails__left">
      <span className="card__subTitle">Current</span>
      <div className="cardReview__wrapper">
       <div className="cardReview__stars">
        {[...Array(5).keys()].map((item) => (
         <img
          src={
           item < Math.trunc(currentReview)
            ? StarFillIcon
            : item < currentReview
            ? StarHalfFillIcon
            : StarIcon
          }
          alt=""
         />
        ))}
       </div>
       <span className="cardReview__number">{currentReview}</span>
      </div>
     </div>
     <div className="divider"></div>
     <div className="cardDetails__right">
      <span className="card__subTitle">Previous</span>
      <span className="cardReview__number">{previousReview}</span>
     </div>
    </div>
   </div>
  </div>
 );
};

export default AirZonesCard;
