import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import React, { useEffect, useMemo, useState } from "react";
import { Fade } from "react-awesome-reveal";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import AccentureLogo from "../assets/accenture-logo.svg";
import AiIllustration from "../assets/ai-illustration/ai-illustration.svg";
import AiBrowseIllustration from "../assets/ai-illustration/browse-illustration.svg";
import AiIllustrationMainImg from "../assets/ai-illustration/Illustration-main.svg";
import AiLaptopIllustration from "../assets/ai-illustration/laptop-illustration.svg";
import RatingCardImg1 from "../assets/airZones-card1-img.png";
import RatingCardImg2 from "../assets/airZones-card2-img.png";
import RatingCardImg3 from "../assets/airZones-card3-img.png";
import AirZonePlatformImg from "../assets/airzones-platform.svg";
import BarclaysLogo from "../assets/barclays-logo.svg";
import DashboardIcon from "../assets/dashboard-icon.svg";
import DashboardImg from "../assets/dashboard-img.png";
import HsbcLogo from "../assets/hsbc-logo.svg";
import ImpactReportImg from "../assets/impact-report-img.png";
import JjlLogo from "../assets/jll-logo.svg";
import ProductImgMobile from "../assets/Photo-product-mobile.png";
import ProductImg from "../assets/Photo-product.png";
import RadissonLogo from "../assets/radisson-logo.svg";
import ReportIcon from "../assets/report-icon.svg";
import SeamlessConnectImg from "../assets/seamless-connect.png";
import SoundWaveIcon from "../assets/soundwave.svg";
import VirginLogo from "../assets/virgin-logo.svg";
import ZurichLogo from "../assets/zurich-logo.svg";
import expoLogo from "../assets/expo.jpg";
import AirZonesCard from "../components/AirzonesCard";
import ListingSection from "../components/ListingSection";
import "../sass/Home.scss";

const Home = () => {
 const [init, setInit] = useState(false);
 // this should be run only once per application lifetime
 useEffect(() => {
  initParticlesEngine(async (engine) => {
   // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
   // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
   // starting from v2 you can add only the features you need reducing the bundle size
   //await loadAll(engine);
   //await loadFull(engine);
   await loadSlim(engine);
   //await loadBasic(engine);
  }).then(() => {
   setInit(true);
  });
 }, []);

 const particlesLoaded = (container) => {};

 const options = useMemo(
  () => ({
   autoPlay: true,

   background: {
    color: {
     value: "transparent",
    },
    image: "",
    position: "50% 50%",
    repeat: "no-repeat",
    size: "30%",
    opacity: 1,
   },
   clear: true,
   defaultThemes: {},
   delay: 0,
   fullScreen: {
    enable: false,
    zIndex: 0,
   },
   detectRetina: true,
   duration: 0,
   fpsLimit: 120,
   interactivity: {
    detectsOn: "window",
    events: {
     onClick: {
      enable: false,
      mode: "repulse",
     },
     onDiv: {
      selectors: {},
      enable: false,
      mode: {},
      type: "circle",
     },
     onHover: {
      enable: false,
      mode: "bubble",
      parallax: {
       enable: false,
       force: 2,
       smooth: 10,
      },
     },
     resize: {
      delay: 0.5,
      enable: true,
     },
    },
    modes: {
     trail: {
      delay: 1,
      pauseOnStop: false,
      quantity: 1,
     },
     attract: {
      distance: 200,
      duration: 0.4,
      easing: "ease-out-quad",
      factor: 1,
      maxSpeed: 50,
      speed: 1,
     },
     bounce: {
      distance: 200,
     },
     bubble: {
      distance: 250,
      duration: 2,
      mix: false,
      opacity: 0,
      size: 0,
      divs: {
       distance: 200,
       duration: 0.4,
       mix: false,
       selectors: {},
      },
     },
     connect: {
      distance: 80,
      links: {
       opacity: 0.5,
      },
      radius: 60,
     },
     grab: {
      distance: 400,
      links: {
       blink: false,
       consent: false,
       opacity: 1,
      },
     },
     push: {
      default: true,
      groups: [],
      quantity: 4,
     },
     remove: {
      quantity: 2,
     },
     repulse: {
      distance: 400,
      duration: 0.4,
      factor: 100,
      speed: 1,
      maxSpeed: 50,
      easing: "ease-out-quad",
      divs: {
       distance: 200,
       duration: 0.4,
       factor: 100,
       speed: 1,
       maxSpeed: 50,
       easing: "ease-out-quad",
       selectors: {},
      },
     },
     slow: {
      factor: 3,
      radius: 200,
     },
     light: {
      area: {
       gradient: {
        start: {
         value: "#ffffff",
        },
        stop: {
         value: "#000000",
        },
       },
       radius: 1000,
      },
      shadow: {
       color: {
        value: "#000000",
       },
       length: 2000,
      },
     },
    },
   },
   manualParticles: [],
   particles: {
    bounce: {
     horizontal: {
      value: 1,
     },
     vertical: {
      value: 1,
     },
    },
    collisions: {
     absorb: {
      speed: 2,
     },
     bounce: {
      horizontal: {
       value: 1,
      },
      vertical: {
       value: 1,
      },
     },
     enable: false,
     maxSpeed: 50,
     mode: "bounce",
     overlap: {
      enable: true,
      retries: 0,
     },
    },
    color: {
     value: "#9BD4EC",
     animation: {
      h: {
       count: 0,
       enable: false,
       speed: 1,
       decay: 0,
       delay: 0,
       sync: true,
       offset: 0,
      },
      s: {
       count: 0,
       enable: false,
       speed: 1,
       decay: 0,
       delay: 0,
       sync: true,
       offset: 0,
      },
      l: {
       count: 0,
       enable: false,
       speed: 1,
       decay: 0,
       delay: 0,
       sync: true,
       offset: 0,
      },
     },
    },
    effect: {
     close: true,
     fill: true,
     options: {},
     type: {},
    },
    groups: [],
    move: {
     angle: {
      offset: 0,
      value: 90,
     },
     attract: {
      distance: 200,
      enable: false,
      rotate: {
       x: 3000,
       y: 3000,
      },
     },
     center: {
      x: 50,
      y: 50,
      mode: "percent",
      radius: 0,
     },
     decay: 0,
     distance: {},
     direction: "none",
     drift: 0,
     enable: true,
     gravity: {
      acceleration: 9.81,
      enable: false,
      inverse: false,
      maxSpeed: 50,
     },
     path: {
      clamp: true,
      delay: {
       value: 0,
      },
      enable: false,
      options: {},
     },
     outModes: {
      default: "out",
      bottom: "out",
      left: "out",
      right: "out",
      top: "out",
     },
     random: false,
     size: false,
     speed: {
      min: 0.1,
      max: 1,
     },
     spin: {
      acceleration: 0,
      enable: false,
     },
     straight: false,
     trail: {
      enable: false,
      length: 10,
      fill: {},
     },
     vibrate: false,
     warp: false,
    },
    number: {
     density: {
      enable: true,
      width: 1920,
      height: 1080,
     },
     limit: {
      mode: "delete",
      value: 0,
     },
     value: 160,
    },
    opacity: {
     value: {
      min: 0.1,
      max: 1,
     },
     animation: {
      count: 0,
      enable: true,
      speed: 1,
      decay: 0,
      delay: 0,
      sync: false,
      mode: "auto",
      startValue: "random",
      destroy: "none",
     },
    },
    reduceDuplicates: false,
    shadow: {
     blur: 0,
     color: {
      value: "#000",
     },
     enable: false,
     offset: {
      x: 0,
      y: 0,
     },
    },
    shape: {
     close: true,
     fill: true,
     options: {},
     type: "circle",
    },
    size: {
     value: {
      min: 2.5,
      max: 5,
     },
     animation: {
      count: 0,
      enable: false,
      speed: 5,
      decay: 0,
      delay: 0,
      sync: false,
      mode: "auto",
      startValue: "random",
      destroy: "none",
     },
    },
    stroke: {
     width: 0,
    },
    zIndex: {
     value: 0,
     opacityRate: 1,
     sizeRate: 1,
     velocityRate: 1,
    },
    destroy: {
     bounds: {},
     mode: "none",
     split: {
      count: 1,
      factor: {
       value: 3,
      },
      rate: {
       value: {
        min: 4,
        max: 9,
       },
      },
      sizeOffset: true,
      particles: {},
     },
    },
    roll: {
     darken: {
      enable: false,
      value: 0,
     },
     enable: false,
     enlighten: {
      enable: false,
      value: 0,
     },
     mode: "vertical",
     speed: 25,
    },
    tilt: {
     value: 0,
     animation: {
      enable: false,
      speed: 0,
      decay: 0,
      sync: false,
     },
     direction: "clockwise",
     enable: false,
    },
    twinkle: {
     lines: {
      enable: false,
      frequency: 0.05,
      opacity: 1,
     },
     particles: {
      enable: false,
      frequency: 0.05,
      opacity: 1,
     },
    },
    wobble: {
     distance: 5,
     enable: false,
     speed: {
      angle: 50,
      move: 10,
     },
    },
    life: {
     count: 0,
     delay: {
      value: 0,
      sync: false,
     },
     duration: {
      value: 0,
      sync: false,
     },
    },
    rotate: {
     value: 0,
     animation: {
      enable: false,
      speed: 0,
      decay: 0,
      sync: false,
     },
     direction: "clockwise",
     path: false,
    },
    orbit: {
     animation: {
      count: 0,
      enable: false,
      speed: 1,
      decay: 0,
      delay: 0,
      sync: false,
     },
     enable: false,
     opacity: 1,
     rotation: {
      value: 45,
     },
     width: 1,
    },
    links: {
     blink: false,
     color: {
      value: "#fff",
     },
     consent: false,
     distance: 100,
     enable: false,
     frequency: 1,
     opacity: 1,
     shadow: {
      blur: 5,
      color: {
       value: "#000",
      },
      enable: false,
     },
     triangles: {
      enable: false,
      frequency: 1,
     },
     width: 1,
     warp: false,
    },
    repulse: {
     value: 0,
     enabled: false,
     distance: 1,
     duration: 1,
     factor: 1,
     speed: 1,
    },
   },
   pauseOnBlur: true,
   pauseOnOutsideViewport: true,
   responsive: [],
   smooth: false,
   style: {},
   themes: [],
   zLayers: 100,
   name: "NASA",
   motion: {
    disable: false,
    reduce: {
     factor: 4,
     value: true,
    },
   },
  }),
  []
 );
 return (
  <div className="home__page">
   {init && (
    <Particles
     id="tsparticles"
     particlesLoaded={particlesLoaded}
     options={options}
    />
   )}
   <section className="section__one">
    <div className="container">
     <div className="sectionOne__left">
      <h2>
       The indoor
       <br />
       <span>Air quality</span> star rating standard
      </h2>
      <p>
       Benchmark, improve and report your indoor air quality with the AirZones
       Star Rating & web platform
      </p>
      <div className="buttons__wrapper">
       <button className="button button--primary">
        <Link to="/contact">Contact Us</Link>
       </button>
       <button className="button button--primary button--icon">
        <Link to="">
         <svg
          width="100%"
          height="100%"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect
           width="50"
           height="50"
           rx="25"
           fill="url(#paint0_linear_5360_10601)"
          />
          <path
           d="M21.6665 21.6667H28.3332V28.3333"
           stroke="#F2F2F2"
           stroke-width="1.5"
           stroke-linecap="round"
           stroke-linejoin="round"
          />
          <path
           d="M21.6665 28.3333L28.3332 21.6667"
           stroke="#F2F2F2"
           stroke-width="1.5"
           stroke-linecap="round"
           stroke-linejoin="round"
          />
          <defs>
           <linearGradient
            id="paint0_linear_5360_10601"
            x1="34.375"
            y1="35.9375"
            x2="62.4459"
            y2="41.9065"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#386CF2" />
            <stop offset="0.54047" stop-color="#4081FF" />
            <stop offset="1" stop-color="#3877F2" />
           </linearGradient>
          </defs>
         </svg>
        </Link>
       </button>
      </div>
     </div>
     <div className="sectionOne__right">
      <Fade triggerOnce fraction={0.5}>
       <picture>
        <source media="(max-width:475px)" srcset={ProductImgMobile} />
        <img src={ProductImg} alt="" />
       </picture>
      </Fade>
     </div>
    </div>
   </section>
   <section className="section__two bg__gray">
    <div className="container">
     <h2 className="section__title">
      Healthy Air, <span>Healthy Buildings,</span> Made Simple
     </h2>
     <div className="sectionTexts__wrapper">
      <p className="section__text">
       The AirZones algorithm coverts complex data into a real time star rating.
      </p>
      <ul className="section__text">
       <li>Meet the air requirements for WELL, LEED & BREEAM</li>
       <li>
        Save time and remove guesswork with a cost effective IAQ solution
       </li>
      </ul>
     </div>
     <div className="sectionCards__wrapper">
      <Fade
       cascade
       damping={0.3}
       triggerOnce
       fraction={0.5}
       style={{ flex: 1 }}>
       <AirZonesCard
        bgImg={RatingCardImg1}
        currentReview="4.0"
        previousReview="4.2"
       />
       <AirZonesCard
        bgImg={RatingCardImg2}
        currentReview="2.6"
        previousReview="2.2"
       />
       <AirZonesCard
        bgImg={RatingCardImg3}
        currentReview="4.2"
        previousReview="4.4"
       />
      </Fade>
     </div>
    </div>
   </section>
   <section className="section__three">
    <div className="container px-0">
     <h2 className="section__title">
      The World’s First <span>“Fitness App”</span> <br /> for Healthy Buildings
     </h2>
     <ListingSection
      textList={[
       "Benchmark estates, see where to start and what to do",
       "All your AirZones rated in one place with instant access to live data and Monthly Impact Reports",
      ]}
      title="Monitor and Benchmark All Your AirZones with the Intuitive AirZones Platform"
      Icon={SoundWaveIcon}
      Img={AirZonePlatformImg}
     />
     <ListingSection
      className="bg__gray"
      flexReverse
      textList={[
       "Real-time indoor air quality",
       "Innovative displays align to green building certifications and make data easy to understand",
       "Engage and educate occupants by promoting your healthy buidings",
      ]}
      title="The AirZones Dashboard"
      Icon={DashboardIcon}
      Img={DashboardImg}
     />
     <ListingSection
      style={{ paddingBottom: "0px" }}
      textList={[
       "Transform your air quality data into a clear, easy to understand, one page report",
       "Benchmark your AirZones with similar buildings and track month to month changes",
       "See how these changes impact the occupants’ health and productivity",
      ]}
      title="The AirZones Impact Report"
      Icon={ReportIcon}
      Img={ImpactReportImg}
      contactBtn
     />
    </div>
   </section>
   <section className="section__four bg__gray">
    <div className="container">
     <h2 className="section__title">
      AI-Generated <span>Actionable</span> Recommendations
     </h2>
     <p className="section__text center">
      Our AI learns the specifics of your AirZones and provides tailored
      recommendations to improve your air quality.
     </p>
     <div className="animation__img">
      <div className="animation__center">
       <img src={AiLaptopIllustration} alt="" className="laptop__img" />
       <img src={AiIllustrationMainImg} alt="" className="mobile__img" />
       <img src={AiIllustration} alt="" className="ai__img" />
       <img src={AiBrowseIllustration} alt="" className="browse__img" />
      </div>
      <div className="circle circle__first">
       <svg
        className="first__icon"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="46" height="46" rx="23" fill="#4081FF" />
        <g clip-path="url(#clip0_7022_366)">
         <path
          d="M20.7742 27.1641H14.6167C14.2768 27.1641 14.001 27.4194 14.001 27.734C14.001 28.0486 14.2768 28.3039 14.6167 28.3039H20.7742C21.1141 28.3039 21.39 28.5603 21.39 28.8738C21.39 29.1873 21.1141 29.4437 20.7742 29.4437C20.4343 29.4437 20.1585 29.699 20.1585 30.0136C20.1585 30.3282 20.4343 30.5835 20.7742 30.5835C21.7927 30.5835 22.6215 29.8164 22.6215 28.8738C22.6215 27.9312 21.7927 27.1641 20.7742 27.1641Z"
          fill="white"
         />
         <path
          d="M22.1725 17.3965C21.8255 17.3965 21.5439 17.6518 21.5439 17.9664C21.5439 18.281 21.8255 18.5363 22.1725 18.5363C22.5195 18.5363 22.8011 18.7916 22.8011 19.1062C22.8011 19.4208 22.5195 19.6761 22.1725 19.6761H14.6296C14.2826 19.6761 14.001 19.9315 14.001 20.246C14.001 20.5606 14.2826 20.816 14.6296 20.816H22.1725C23.2122 20.816 24.0582 20.0489 24.0582 19.1062C24.0582 18.1636 23.2122 17.3965 22.1725 17.3965Z"
          fill="white"
         />
         <path
          d="M27.1528 18.8691C26.8071 18.8691 26.5265 19.1536 26.5265 19.5042C26.5265 19.8547 26.8071 20.1392 27.1528 20.1392C28.1886 20.1392 29.0316 20.994 29.0316 22.0444C29.0316 23.0947 28.1886 23.9495 27.1528 23.9495H14.6273C14.2815 23.9495 14.001 24.234 14.001 24.5845C14.001 24.9351 14.2815 25.2196 14.6273 25.2196H27.1528C28.8801 25.2196 30.2842 23.7946 30.2842 22.0444C30.2842 20.2942 28.8801 18.8691 27.1528 18.8691Z"
          fill="white"
         />
         <path
          d="M25.9665 28.6523C24.9103 28.6523 24.0508 29.4194 24.0508 30.3621C24.0508 31.3047 24.9103 32.0718 25.9665 32.0718C27.0226 32.0718 27.8821 31.3047 27.8821 30.3621C27.8821 29.4194 27.0226 28.6523 25.9665 28.6523ZM25.9665 30.932C25.6152 30.932 25.3279 30.6755 25.3279 30.3621C25.3279 30.0486 25.6152 29.7922 25.9665 29.7922C26.3177 29.7922 26.605 30.0486 26.605 30.3621C26.605 30.6755 26.3177 30.932 25.9665 30.932Z"
          fill="white"
         />
         <path
          d="M32.1911 25.2129C31.1349 25.2129 30.2754 25.98 30.2754 26.9226C30.2754 27.8653 31.1349 28.6324 32.1911 28.6324C33.2472 28.6324 34.1067 27.8653 34.1067 26.9226C34.1067 25.98 33.2472 25.2129 32.1911 25.2129ZM32.1911 27.4925C31.8399 27.4925 31.5525 27.2361 31.5525 26.9226C31.5525 26.6092 31.8399 26.3527 32.1911 26.3527C32.5423 26.3527 32.8296 26.6092 32.8296 26.9226C32.8296 27.2361 32.5423 27.4925 32.1911 27.4925Z"
          fill="white"
         />
         <path
          d="M32.1911 15.9238C31.1349 15.9238 30.2754 16.6909 30.2754 17.6336C30.2754 18.5762 31.1349 19.3433 32.1911 19.3433C33.2472 19.3433 34.1067 18.5762 34.1067 17.6336C34.1067 16.6909 33.2472 15.9238 32.1911 15.9238ZM32.1911 18.2035C31.8399 18.2035 31.5525 17.9482 31.5525 17.6336C31.5525 17.319 31.8399 17.0637 32.1911 17.0637C32.5423 17.0637 32.8296 17.319 32.8296 17.6336C32.8296 17.9482 32.5423 18.2035 32.1911 18.2035Z"
          fill="white"
         />
         <path
          d="M27.404 13.494C26.3478 13.494 25.4883 14.2611 25.4883 15.2037C25.4883 16.1463 26.3478 16.9134 27.404 16.9134C28.4601 16.9134 29.3196 16.1463 29.3196 15.2037C29.3196 14.2611 28.4601 13.494 27.404 13.494ZM27.404 15.7736C27.0527 15.7736 26.7654 15.5183 26.7654 15.2037C26.7654 14.8891 27.0527 14.6338 27.404 14.6338C27.7552 14.6338 28.0425 14.8891 28.0425 15.2037C28.0425 15.5183 27.7552 15.7736 27.404 15.7736Z"
          fill="white"
         />
        </g>
        <defs>
         <clipPath id="clip0_7022_366">
          <rect
           width="20.1145"
           height="20.0283"
           fill="white"
           transform="translate(14 13)"
          />
         </clipPath>
        </defs>
       </svg>
      </div>
      <div className="circle circle__second">
       <div className="circle__border"></div>
       <svg
        className="first__icon"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="46" height="46" rx="23" fill="#4081FF" />
        <g clip-path="url(#clip0_7016_750)">
         <path
          d="M23.8093 24.537V16.1488C23.8093 14.4136 22.4093 13.002 20.6886 13.002C18.9678 13.002 17.5679 14.4136 17.5679 16.1488V24.537C16.5039 25.4564 15.8994 26.7755 15.8994 28.2008C15.8995 30.8638 18.0478 33.0303 20.6886 33.0303C23.3294 33.0303 25.4778 30.8638 25.4778 28.2008C25.4778 26.7755 24.8733 25.4564 23.8093 24.537ZM20.6886 31.8568C18.6895 31.8568 17.0632 30.2167 17.0632 28.2008C17.0632 27.0462 17.5874 25.9834 18.5013 25.285C18.6464 25.1741 18.7317 25.001 18.7317 24.8174V16.1488C18.7317 15.0607 19.6096 14.1755 20.6886 14.1755C21.7677 14.1755 22.6455 15.0607 22.6455 16.1488V24.8174C22.6455 25.001 22.7308 25.1741 22.876 25.285C23.7899 25.9834 24.3141 27.0462 24.3141 28.2008C24.314 30.2167 22.6877 31.8568 20.6886 31.8568Z"
          fill="white"
         />
         <path
          d="M21.2482 25.6071V16.0255C21.2482 15.7051 20.9771 15.4453 20.6428 15.4453C20.3085 15.4453 20.0375 15.7051 20.0375 16.0255V25.6071C18.8759 25.8699 18.0088 26.8718 18.0088 28.0642C18.0088 29.4562 19.1904 30.5887 20.6428 30.5887C22.0953 30.5887 23.2769 29.4562 23.2769 28.0642C23.2769 26.8718 22.4098 25.8699 21.2482 25.6071ZM20.6428 29.4284C19.858 29.4284 19.2194 28.8165 19.2194 28.0643C19.2194 27.3121 19.858 26.7001 20.6428 26.7001C21.4277 26.7001 22.0662 27.3121 22.0662 28.0643C22.0662 28.8165 21.4277 29.4284 20.6428 29.4284Z"
          fill="white"
         />
         <path
          d="M26.0303 16.9108H30.662C30.97 16.9108 31.2197 16.5827 31.2197 16.1781C31.2197 15.7734 30.97 15.4453 30.662 15.4453H26.0303C25.7223 15.4453 25.4727 15.7734 25.4727 16.1781C25.4727 16.5827 25.7223 16.9108 26.0303 16.9108Z"
          fill="white"
         />
         <path
          d="M26.0175 19.3542H28.2802C28.5811 19.3542 28.8251 19.0261 28.8251 18.6214C28.8251 18.2167 28.5811 17.8887 28.2802 17.8887H26.0175C25.7166 17.8887 25.4727 18.2167 25.4727 18.6214C25.4727 19.0261 25.7166 19.3542 26.0175 19.3542Z"
          fill="white"
         />
         <path
          d="M30.662 19.8418H26.0303C25.7223 19.8418 25.4727 20.1699 25.4727 20.5745C25.4727 20.9792 25.7223 21.3073 26.0303 21.3073H30.662C30.97 21.3073 31.2197 20.9792 31.2197 20.5745C31.2197 20.1699 30.97 19.8418 30.662 19.8418Z"
          fill="white"
         />
         <path
          d="M28.2802 22.7676H26.0175C25.7166 22.7676 25.4727 23.0957 25.4727 23.5003C25.4727 23.905 25.7166 24.2331 26.0175 24.2331H28.2802C28.5811 24.2331 28.8251 23.905 28.8251 23.5003C28.8251 23.0957 28.5811 22.7676 28.2802 22.7676Z"
          fill="white"
         />
        </g>
        <defs>
         <clipPath id="clip0_7016_750">
          <rect
           width="20.1145"
           height="20.0283"
           fill="white"
           transform="translate(14 13)"
          />
         </clipPath>
        </defs>
       </svg>
       <svg
        className="second__icon"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="62" height="62" rx="31" fill="#4081FF" />
        <g clip-path="url(#clip0_7016_762)">
         <path
          d="M43.9426 28.0121C43.0294 26.8755 41.8 26.0424 40.4346 25.6245C40.1047 23.5982 39.1191 21.7411 37.6205 20.3438C35.963 18.7984 33.8146 17.9473 31.5709 17.9473C29.3466 17.9473 27.2496 18.7672 25.6038 20.2682C25.0282 20.041 24.4116 19.9227 23.7912 19.9227C21.0185 19.9227 18.7627 22.2317 18.7627 25.0699C18.7627 25.421 18.7973 25.7692 18.8659 26.1102C17.6761 27.3505 17.0029 29.0217 17.0029 30.7736C17.0029 32.1573 17.414 33.4841 18.1917 34.6106C18.8547 35.571 19.7396 36.3253 20.771 36.8139C21.4765 40.6174 24.7518 43.4267 28.5839 43.4267C29.6309 43.4267 30.6418 43.2237 31.596 42.8225C32.5366 43.4933 33.6615 43.8578 34.8165 43.8578C36.1155 43.8578 37.3832 43.3943 38.3862 42.5525C39.231 41.8433 39.8574 40.894 40.1861 39.8378C43.3426 39.0039 45.5808 36.0838 45.5808 32.6953C45.5808 30.9903 44.9989 29.3271 43.9426 28.0121ZM39.3603 38.2695C39.0252 38.3312 38.7599 38.5945 38.6894 38.9355C38.305 40.7946 36.6762 42.1439 34.8165 42.1439C33.8772 42.1439 32.9664 41.8011 32.2521 41.1786C32.0974 41.0439 31.9043 40.9742 31.7092 40.9742C31.584 40.9742 31.4581 41.0028 31.3411 41.0615C30.4791 41.4937 29.5513 41.7128 28.5838 41.7128C25.4278 41.7128 22.7501 39.3001 22.3553 36.1006C22.3163 35.7845 22.1091 35.5166 21.8174 35.4052C19.9393 34.6875 18.6774 32.8262 18.6774 30.7736C18.6774 29.3376 19.2866 27.9745 20.3489 27.0341C20.603 26.8091 20.7011 26.4506 20.5981 26.1233C20.4913 25.7839 20.4372 25.4294 20.4372 25.0698C20.4372 23.1768 21.9418 21.6366 23.7912 21.6366C24.3438 21.6366 24.8913 21.777 25.3747 22.0426C25.7018 22.2224 26.1058 22.1601 26.3666 21.8895C27.7515 20.4526 29.5998 19.6613 31.5709 19.6613C35.3464 19.6613 38.4742 22.5568 38.8465 26.3966C38.8826 26.7691 39.1508 27.0745 39.5088 27.151C42.0569 27.6948 43.9063 30.0265 43.9063 32.6953C43.9063 35.44 41.9944 37.7842 39.3603 38.2695Z"
          fill="white"
         />
         <path
          d="M27.5043 32.3615C27.144 32.0443 26.6069 32.0938 26.3048 32.4719C26.2594 32.5287 26.2105 32.5817 26.1594 32.6295C25.8982 32.8736 25.5622 32.9975 25.1608 32.9975C24.0581 32.9975 23.1609 32.0558 23.1609 30.8983C23.1609 29.7408 24.0581 28.7992 25.1608 28.7992C25.5617 28.7992 25.9481 28.923 26.2784 29.1571C26.668 29.4335 27.1973 29.3258 27.4605 28.9168C27.7238 28.5078 27.6213 27.9523 27.2316 27.676C26.6187 27.2415 25.9026 27.0117 25.1608 27.0117C23.119 27.0117 21.458 28.7553 21.458 30.8983C21.458 33.0413 23.1191 34.7849 25.1608 34.7849C25.9808 34.7849 26.7177 34.5012 27.292 33.9643C27.4044 33.8592 27.5113 33.7435 27.6095 33.6206C27.9117 33.2423 27.8646 32.6786 27.5043 32.3615Z"
          fill="white"
         />
         <path
          d="M38.3872 34.9754C38.3837 34.9754 38.38 34.9754 38.3764 34.9755C38.2641 34.977 38.1485 34.9783 38.0324 34.9795C38.1656 34.7878 38.3152 34.5699 38.4827 34.3221C38.7636 33.9065 38.9408 33.5229 39.0244 33.1493C39.0496 33.037 39.0653 32.913 39.0653 32.7975C39.0653 31.7514 38.2434 30.9004 37.233 30.9004C36.3593 30.9004 35.6031 31.544 35.4349 32.4308C35.3452 32.9034 35.6426 33.3618 36.0992 33.4546C36.555 33.5473 36.9984 33.2394 37.0879 32.7668C37.0994 32.7061 37.1529 32.6445 37.2329 32.6445C37.3037 32.6445 37.3848 32.7098 37.3748 32.7888C37.3483 32.8843 37.2796 33.0599 37.1024 33.3221C36.4943 34.2216 36.1318 34.7128 35.9371 34.9768C35.68 35.3253 35.458 35.6263 35.5939 36.0837C35.6754 36.3581 35.8828 36.5767 36.1488 36.6686C36.26 36.707 36.3274 36.7303 37.1217 36.7303C37.4259 36.7303 37.8369 36.7268 38.3976 36.7196C38.8627 36.7135 39.235 36.3182 39.2293 35.8366C39.2235 35.3586 38.8476 34.9754 38.3872 34.9754Z"
          fill="white"
         />
         <path
          d="M31.6092 27.0117C29.5081 27.0117 27.7988 28.7552 27.7988 30.8983C27.7988 33.0414 29.5082 34.7849 31.6092 34.7849C33.7103 34.7849 35.4196 33.0414 35.4196 30.8983C35.4196 28.7552 33.7102 27.0117 31.6092 27.0117ZM31.6092 32.9975C30.4744 32.9975 29.5512 32.0558 29.5512 30.8983C29.5512 29.7408 30.4744 28.7991 31.6092 28.7991C32.7439 28.7991 33.6672 29.7408 33.6672 30.8983C33.6672 32.0558 32.7439 32.9975 31.6092 32.9975Z"
          fill="white"
         />
        </g>
        <defs>
         <clipPath id="clip0_7016_762">
          <rect
           width="28.5778"
           height="29.1494"
           fill="white"
           transform="translate(17 16)"
          />
         </clipPath>
        </defs>
       </svg>
      </div>
      <div className="circle circle__third">
       <div className="circle__border"></div>
       <svg
        className="first__icon"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="62" height="62" rx="31" fill="#4081FF" />
        <g clip-path="url(#clip0_5360_12939)">
         <path
          d="M26.6888 22.8611C28.0484 21.128 29.4546 19.3384 30.5881 17.4161C30.7377 17.1637 31.0103 17.0077 31.3045 17.0077C31.5987 17.0077 31.8713 17.1637 32.0192 17.4161C33.1528 19.3368 34.559 21.128 35.9186 22.8594C38.6744 26.3688 41.2773 29.6839 41.2773 33.6083C41.2773 39.1014 36.8045 43.5686 31.3045 43.5686C25.8045 43.5686 21.3317 39.1014 21.3317 33.6083C21.3317 29.6856 23.9329 26.3704 26.6888 22.8611ZM31.3045 41.9086C35.887 41.9086 39.6152 38.1851 39.6152 33.6083C39.6152 30.2566 37.1852 27.1623 34.6105 23.8837C33.4869 22.4527 32.3334 20.9835 31.3045 19.4098C30.2757 20.9835 29.1221 22.4543 27.9985 23.8853C25.4239 27.1623 22.9938 30.2583 22.9938 33.6083C22.9938 38.1851 26.722 41.9086 31.3045 41.9086Z"
          fill="white"
         />
         <path
          d="M33.6884 26.2486C35.063 26.2486 36.1816 27.3896 36.1816 28.7917C36.1816 30.1937 35.063 31.3347 33.6884 31.3347C32.3138 31.3347 31.1952 30.1937 31.1952 28.7917C31.1952 27.3896 32.3138 26.2486 33.6884 26.2486ZM33.6884 29.6394C34.1472 29.6394 34.5195 29.2596 34.5195 28.7917C34.5195 28.3237 34.1472 27.944 33.6884 27.944C33.2297 27.944 32.8574 28.3237 32.8574 28.7917C32.8574 29.2596 33.2297 29.6394 33.6884 29.6394Z"
          fill="white"
         />
         <path
          d="M28.6982 34.7243C30.0728 34.7243 31.1914 35.8653 31.1914 37.2674C31.1914 38.6695 30.0728 39.8105 28.6982 39.8105C27.3236 39.8105 26.205 38.6695 26.205 37.2674C26.205 35.8653 27.3236 34.7243 28.6982 34.7243ZM28.6982 38.1151C29.1553 38.1151 29.5293 37.7336 29.5293 37.2674C29.5293 36.8012 29.1553 36.4197 28.6982 36.4197C28.2411 36.4197 27.8671 36.8012 27.8671 37.2674C27.8671 37.7336 28.2411 38.1151 28.6982 38.1151Z"
          fill="white"
         />
         <path
          d="M26.4519 28.1678C26.7761 27.8555 27.303 27.8555 27.6272 28.1678L35.9385 36.1748C36.2627 36.4871 36.2627 36.9947 35.9385 37.307C35.7756 37.4623 35.5629 37.5408 35.3501 37.5408C35.1373 37.5408 34.9245 37.4623 34.7633 37.307L26.4519 29.3C26.1278 28.9877 26.1278 28.4801 26.4519 28.1678Z"
          fill="white"
         />
        </g>
        <defs>
         <clipPath id="clip0_5360_12939">
          <rect
           width="26.5942"
           height="26.5609"
           fill="white"
           transform="matrix(-1 0 0 1 44.5938 17)"
          />
         </clipPath>
        </defs>
       </svg>
       <svg
        className="second__icon"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="62" height="62" rx="31" fill="#4081FF" />
        <g clip-path="url(#clip0_5360_12956)">
         <path
          d="M43.2473 37.6934C41.9448 37.6934 41.2461 38.1512 40.6845 38.5191C40.1843 38.8469 39.8227 39.0837 39.0031 39.0837C38.1838 39.0837 37.8222 38.8467 37.322 38.5191C36.7604 38.1512 36.0617 37.6934 34.759 37.6934C33.4564 37.6934 32.7577 38.1512 32.1963 38.5191C31.6959 38.8469 31.3345 39.0837 30.515 39.0837C29.6957 39.0837 29.3342 38.8467 28.8341 38.5191C28.2725 38.1512 27.5738 37.6934 26.2712 37.6934C24.9686 37.6934 24.2699 38.1512 23.7083 38.5191C23.2081 38.8469 22.8467 39.0837 22.0272 39.0837C21.2079 39.0837 20.8465 38.8467 20.3461 38.5191C19.7847 38.1512 19.086 37.6934 17.7834 37.6934C17.353 37.6934 17.0039 38.0269 17.0039 38.4382V42.855C17.0039 43.2663 17.353 43.5998 17.7834 43.5998H43.2473C43.6778 43.5998 44.0269 43.2663 44.0269 42.855V38.4382C44.0269 38.0269 43.6778 37.6934 43.2473 37.6934ZM42.4678 42.1102H18.563V39.2807C18.9031 39.3798 19.1578 39.5466 19.4645 39.7475C20.0259 40.1153 20.7248 40.5733 22.0274 40.5733C23.3298 40.5733 24.0287 40.1153 24.5901 39.7475C25.0905 39.4197 25.4519 39.183 26.2712 39.183C27.0905 39.183 27.4519 39.4197 27.9523 39.7475C28.5137 40.1153 29.2126 40.5733 30.515 40.5733C31.8175 40.5733 32.5165 40.1153 33.0779 39.7475C33.578 39.4197 33.9396 39.183 34.759 39.183C35.5785 39.183 35.9399 39.4197 36.4403 39.7475C37.0017 40.1153 37.7006 40.5733 39.0031 40.5733C40.3057 40.5733 41.0046 40.1153 41.566 39.7475C41.873 39.5464 42.1276 39.3798 42.4678 39.2807V42.1102Z"
          fill="white"
         />
         <path
          d="M22.6627 32.7891C22.172 33.2139 21.5 33.796 21.5 34.941C21.5 35.3654 21.8189 35.7096 22.2122 35.7096C22.6054 35.7096 22.9243 35.3654 22.9243 34.941C22.9243 34.5684 23.097 34.3837 23.5543 33.9877C24.045 33.5627 24.717 32.9807 24.717 31.8357C24.717 30.6907 24.045 30.1086 23.5543 29.6836C23.097 29.2876 22.9243 29.1028 22.9243 28.7303C22.9243 28.3579 23.097 28.1731 23.5541 27.7771C24.045 27.3521 24.717 26.7702 24.717 25.6252C24.717 24.4802 24.045 23.8982 23.5541 23.4732C23.097 23.0773 22.9243 22.8925 22.9243 22.5201C22.9243 22.1475 23.097 21.9628 23.5541 21.567C24.045 21.142 24.717 20.5598 24.717 19.415C24.717 18.9906 24.3981 18.6465 24.0049 18.6465C23.6116 18.6465 23.2927 18.9906 23.2927 19.415C23.2927 19.7874 23.12 19.9722 22.6629 20.3681C22.172 20.793 21.5 21.3751 21.5 22.5199C21.5 23.6649 22.172 24.247 22.6629 24.6719C23.12 25.0678 23.2927 25.2526 23.2927 25.6252C23.2927 25.9976 23.12 26.1823 22.6627 26.5783C22.172 27.0033 21.5 27.5853 21.5 28.7303C21.5 29.8752 22.172 30.4574 22.6627 30.8824C23.12 31.2784 23.2927 31.4632 23.2927 31.8355C23.2927 32.2081 23.12 32.3931 22.6627 32.7891Z"
          fill="white"
         />
         <path
          d="M27.8113 32.7891C27.3205 33.2139 26.6484 33.796 26.6484 34.941C26.6484 35.3654 26.9673 35.7096 27.3606 35.7096C27.7539 35.7096 28.0727 35.3654 28.0727 34.941C28.0727 34.5684 28.2456 34.3837 28.7028 33.9877C29.1934 33.5627 29.8655 32.9807 29.8655 31.8357C29.8655 30.6907 29.1934 30.1086 28.7028 29.6836C28.2456 29.2876 28.0727 29.1028 28.0727 28.7303C28.0727 28.3579 28.2456 28.1731 28.7028 27.7771C29.1934 27.3521 29.8655 26.7702 29.8655 25.6252C29.8655 24.4802 29.1934 23.8982 28.7028 23.4732C28.2456 23.0773 28.0727 22.8925 28.0727 22.5201C28.0727 22.1475 28.2456 21.9628 28.7028 21.567C29.1934 21.142 29.8655 20.5598 29.8655 19.415C29.8655 18.9906 29.5466 18.6465 29.1533 18.6465C28.76 18.6465 28.4412 18.9906 28.4412 19.415C28.4412 19.7874 28.2684 19.9722 27.8113 20.3681C27.3207 20.7932 26.6484 21.3751 26.6484 22.5201C26.6484 23.6649 27.3207 24.247 27.8113 24.6719C28.2684 25.068 28.4412 25.2526 28.4412 25.6252C28.4412 25.9976 28.2684 26.1823 27.8113 26.5783C27.3205 27.0033 26.6484 27.5853 26.6484 28.7303C26.6484 29.8752 27.3205 30.4574 27.8113 30.8824C28.2684 31.2784 28.4412 31.4632 28.4412 31.8357C28.4412 32.2081 28.2684 32.3931 27.8113 32.7891Z"
          fill="white"
         />
         <path
          d="M32.3131 32.7891C31.8224 33.2139 31.1504 33.796 31.1504 34.941C31.1504 35.3654 31.4693 35.7096 31.8625 35.7096C32.2558 35.7096 32.5747 35.3654 32.5747 34.941C32.5747 34.5684 32.7474 34.3837 33.2047 33.9877C33.6954 33.5627 34.3674 32.9807 34.3674 31.8357C34.3674 30.6907 33.6954 30.1086 33.2047 29.6836C32.7474 29.2876 32.5747 29.1028 32.5747 28.7303C32.5747 28.3579 32.7474 28.1731 33.2045 27.7771C33.6954 27.3521 34.3674 26.7702 34.3674 25.6252C34.3674 24.4802 33.6954 23.8982 33.2045 23.4732C32.7474 23.0773 32.5747 22.8925 32.5747 22.5201C32.5747 22.1475 32.7474 21.9628 33.2045 21.567C33.6954 21.142 34.3674 20.5598 34.3674 19.415C34.3674 18.9906 34.0485 18.6465 33.6553 18.6465C33.262 18.6465 32.9431 18.9906 32.9431 19.415C32.9431 19.7874 32.7704 19.9722 32.3133 20.3681C31.8224 20.7932 31.1504 21.3751 31.1504 22.5201C31.1504 23.6649 31.8224 24.247 32.3133 24.6719C32.7704 25.068 32.9431 25.2526 32.9431 25.6252C32.9431 25.9976 32.7704 26.1823 32.3131 26.5783C31.8224 27.0033 31.1504 27.5853 31.1504 28.7303C31.1504 29.8752 31.8224 30.4574 32.3131 30.8824C32.7704 31.2784 32.9431 31.4632 32.9431 31.8357C32.9431 32.2081 32.7704 32.3931 32.3131 32.7891Z"
          fill="white"
         />
         <path
          d="M36.8191 32.7891C36.3285 33.2139 35.6564 33.796 35.6564 34.941C35.6564 35.3654 35.9751 35.7096 36.3684 35.7096C36.7619 35.7096 37.0806 35.3654 37.0806 34.941C37.0806 34.5684 37.2535 34.3837 37.7106 33.9877C38.2012 33.5627 38.8733 32.9807 38.8733 31.8357C38.8733 30.6907 38.2012 30.1086 37.7106 29.6836C37.2535 29.2876 37.0806 29.1028 37.0806 28.7303C37.0806 28.3579 37.2535 28.1731 37.7106 27.7771C38.2012 27.3521 38.8733 26.7702 38.8733 25.6252C38.8733 24.4802 38.2012 23.8982 37.7106 23.4732C37.2535 23.0773 37.0806 22.8925 37.0806 22.5201C37.0806 22.1475 37.2535 21.9628 37.7106 21.567C38.2012 21.142 38.8733 20.5598 38.8733 19.415C38.8733 18.9906 38.5544 18.6465 38.1611 18.6465C37.7678 18.6465 37.449 18.9906 37.449 19.415C37.449 19.7874 37.2762 19.9722 36.8191 20.3681C36.3283 20.7932 35.6562 21.3751 35.6562 22.5201C35.6562 23.6649 36.3283 24.247 36.8189 24.6719C37.2762 25.068 37.449 25.2526 37.449 25.6252C37.449 25.9976 37.2762 26.1823 36.8189 26.5783C36.3283 27.0033 35.6562 27.5853 35.6562 28.7303C35.6562 29.8752 36.3283 30.4574 36.8189 30.8824C37.2762 31.2784 37.449 31.4632 37.449 31.8357C37.449 32.2081 37.2762 32.3931 36.8191 32.7891Z"
          fill="white"
         />
        </g>
        <defs>
         <clipPath id="clip0_5360_12956">
          <rect x="17" y="18" width="27.023" height="26.9071" fill="white" />
         </clipPath>
        </defs>
       </svg>
      </div>
     </div>
    </div>
   </section>
   <section className="section__five">
    <div className="container">
     <div>
      <h2 className="section__title">
       Trusted <span>by Global</span> Brands
      </h2>
      <p className="section__text center">
       Selected by Europe's top startup accelerators <br /> AirZones is
       impacting billions of breaths already.
      </p>
     </div>
     <div className="brand__count">
      <span>
       <CountUp
        end={150}
        enableScrollSpy
        scrollSpyOnce
        scrollSpyDelay={500}
        duration={5}
       />
       +
      </span>
      <p>AirZones created</p>
     </div>
     <div className="brand__logos">
      <div className="brand__logo">
       <img src={AccentureLogo} alt="" />
      </div>
      <div className="brand__logo">
       <img src={ZurichLogo} alt="" />
      </div>
      <div className="brand__logo">
       <img src={RadissonLogo} alt="" />
      </div>
      <div className="brand__logo">
       <img src={BarclaysLogo} alt="" />
      </div>
      <div className="brand__logo">
       <img src={VirginLogo} alt="" />
      </div>
      <div className="brand__logo">
       <img src={HsbcLogo} alt="" />
      </div>
      <div className="brand__logo">
       <img src={JjlLogo} alt="" />
      </div>
      <div className="brand__logo">
       <img src={expoLogo} alt=""  width="110" />
      </div>
     </div>
    </div>
   </section>
   <section className="section__six bg__gray">
    <div className="container">
     <h2 className="section__title">
      Cost Effective <br /> <span>Subscription</span> Model
     </h2>
     <p className="section__text center">
      An AirZones subscription links air quality monitors straight <br /> to our
      platform in one simple step.
     </p>
     <div className="section__bottom">
      <Fade triggerOnce fraction={0.5} className="section__left">
       <img src={SeamlessConnectImg} alt="" />
      </Fade>
      <div className="section__right">
       <h3>
        Seamlessly connect your air quality monitors to our platform with an
        AirZones subscription
       </h3>
       <div className="options">
        <p>
         <strong>Option 1:</strong> Combine RESET-certified indoor air quality
         monitors with an AirZones subscription for a complete solution.
        </p>
        <p>
         <strong>Option 2:</strong> Already have monitors? Integrate them with
         AirZones to benchmark, report and improve air quality with confidence.
        </p>
       </div>
       <div className="separator">
        <p className="button__text">Tell us about your specific needs.</p>
        <button className="button button--primary button--hover">
         <Link to="/contact">Contact Us</Link>
        </button>
       </div>
      </div>
     </div>
    </div>
   </section>
  </div>
 );
};

export default Home;
