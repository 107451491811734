// src/Toast.js
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Toast = () => {
  return (
    <ToastContainer />
  );
};

export const notifyOnline = () => {
  toast.success('You are back online!', {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const notifyOffline = () => {
  toast.error('You are offline!', {
    position: toast.POSITION.TOP_RIGHT,
  });
};
