import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/Logo.svg";
import "../sass/Header.scss";

const Header = () => {
 const [isMenuOpen, setIsMenuOpen] = useState(false);
 useEffect(() => {
  document.body.style.overflow = isMenuOpen ? "hidden" : "auto";
 }, [isMenuOpen]);
 const navLinkFunc = () => {
  setIsMenuOpen(false);
 };
 return (
  <header className="header">
   <div className="container">
    <h1 className="logo">
     <Link to="/">
      <img src={Logo} alt="" />
     </Link>
    </h1>
    <div className="navbar__wrapper">
     <button
      className={`hamburger__button ${isMenuOpen ? "active" : ""}`}
      onClick={() => setIsMenuOpen(!isMenuOpen)}>
      <div className="line line--one"></div>
      <div className="line line--two"></div>
     </button>
     <nav className={`navbar ${isMenuOpen ? "active" : ""}`}>
      <NavLink
       to="/contact"
       className={({ isActive, isPending }) =>
        isActive ? "nav__item-active" : ""
       }
       onClick={navLinkFunc}>
       Contact Us
      </NavLink>
      <NavLink to="/coming-soon" onClick={navLinkFunc}>
       Case Studies
      </NavLink>
      <NavLink to="/coming-soon" onClick={navLinkFunc}>
       Media
      </NavLink>
     </nav>
    </div>
   </div>
  </header>
 );
};

export default Header;
